import "./index.css";
import Contain from "../../global/Contain";
import Title from "../../global/Title";
import { SITE_DOMAIN } from "../../config/siteConfig";
import Header from "../../global/Header";
import Footer from "../../global/Footer"
const Cookies = () => {
    return (
        <div>
            <Header />
            <Contain>
                <Title titulo="Uso de cookies" />
                <div className="Cookies-main">
                    <h3>¿Qué son las&nbsp;<em>cookies</em>&nbsp;</h3>
                    <p>Una&nbsp;<em>cookie</em>&nbsp;es un
                        archivo creado por un sitio web que contiene pequeñas cantidades de datos y que
                        se envían entre un emisor y un receptor. En el caso de Internet el emisor
                        sería el servidor donde está alojada la página web y el receptor es el
                        navegador que usas para visitar cualquier página web.</p>

                    <p>Su propósito principal es&nbsp;identificar al usuario almacenando su historial de actividad en un
                        sitio web específico, de manera que se le pueda ofrecer el contenido más
                        apropiado según sus hábitos. Esto quiere decir que cada vez que se visita una
                        página web por primera vez, se guarda una&nbsp;<em>cookie</em>&nbsp;en el
                        navegador con un poco de información. Luego, cuando se visita nuevamente la
                        misma página, el servidor pide la misma&nbsp;<em>cookie</em>&nbsp;para arreglar
                        la configuración del sitio y hacer la visita del usuario tan personalizada como
                        sea posible.</p>

                    <p>Estas <em>cookies</em> pueden tener una finalidad simple, como saber cuándo fue la última vez que el usuario entró a cierta página web; o algo más importante como es guardar todos los artículos puestos en el carrito de compras de una tienda, una acción que se va guardando en tiempo real.</p>

                    <h3>¿Cuáles son los tipos de&nbsp;<em>cookies</em>&nbsp;más
                        comunes?</h3>

                    <p>Existen varios tipos de&nbsp;<em>cookies</em>,
                        pero a las más comunes se les llama&nbsp;<em>sesión
                            cookies</em>, que tienen un corto tiempo de vida ya que&nbsp;<strong>son borradas cuando cierras el navegador</strong>.
                        También tenemos&nbsp;<em>persistent
                            cookies</em>&nbsp;o&nbsp;<em>cookies</em>&nbsp;persistentes,
                        que se usan para rastrear al usuario guardando información sobre su
                        comportamiento en un sitio web durante un período de tiempo determinado; las&nbsp;<em>cookies</em>&nbsp;persistentes&nbsp;<strong>pueden ser borradas limpiando los datos del
                            navegador pero algunas tienen una fecha de expiración</strong>.</p>

                    <p>Las&nbsp;<em>secure
                        cookies</em>&nbsp;o&nbsp;<em>cookies</em>&nbsp;seguras
                        almacenan información cifrada para evitar que los datos almacenados en ellas
                        sean vulnerables a ataques maliciosos de terceros.&nbsp;<strong>Se usan sólo en conexiones</strong>&nbsp;<a href="http://blogthinkbig.com/que-es-https/" target="_blank" rel="noreferrer noopener">HTTPS</a>.</p>

                    <p>Las&nbsp;<em>zombie
                        cookies</em>&nbsp;son
                        interesantes porque se recrean a sí mismas luego de que son borradas. Esto
                        quiere decir que el navegador realmente no tiene ningún poder sobre ellas
                        porque continuarán regenerándose, de ahí el nombre tan creativo que tienen. Las&nbsp;<em>cookies</em>&nbsp;zombis
                        se guardan en el dispositivo y no en el navegador, usualmente con la finalidad
                        de que se pueda acceder a ellas sin importar qué navegador se esté usando. Esta
                        misma característica puede convertirlas en una&nbsp;<strong>amenaza para la privacidad y seguridad del
                            usuario</strong>, y en muchas ocasiones son usadas con fines ilegítimos
                        y malintencionados.</p>

                    <p>¿Qué cookies utiliza dodoviajes.com?</p>

                    <ul><li>Cookies publicitarios.</li><li>Cookies de análisis.</li><li>Cookies de personalización. </li><li>Cookies de publicidad
                        comportamental</li></ul>

                    <p>¿Deshabilitar cookies?</p>

                    <ul><li>Chrome: Configuración &gt;
                        Mostrar opciones avanzadas &gt; Privacidad &gt; Configuración de contenido.</li><li>Firefox: Herramientas &gt;
                            Opciones &gt; Privacidad &gt; Historial &gt; Configuración Personalizadas.</li><li>Explore: Herramientas &gt;
                                Opciones de Internet &gt; Privacidad &gt; Configuración.</li></ul>

                    <p>Aceptación de Cookies:</p>

                    <p>El usuario acepta expresamente, por la
                        utilización de este sitio, el tratamiento de la información recabada en la
                        forma y con los fines anteriormente mencionados.&nbsp; Así mismo reconoce haber leído la información
                        anterior mencionada para la deshabilitar los cookies dependiendo del navegar
                        utilizado para abrir el sitio <a href={SITE_DOMAIN}>www.dodoviajes.com</a>®.</p>
                </div>
            </Contain>
            <Footer/>
        </div>
    )
}
export default Cookies;