import "./index.css"
import {formatDinero} from "./../../utils/getDinero";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
const PaqueteCard = (props) => {
    const {id=0, clavePaquete="", folio="", nombre="", dias="", noches="", precio=0, src="", moneda="MXN"} = props;
    const navigate = useNavigate()
    const onAction = () => {
        navigate(`/paquete/${id}/${clavePaquete}/${folio}`, { state: { id: id } })
    }
    return (
        <div className="PaqueteCard-Container">
            <div className="PaqueteCard-Container-boxImagen">
                <img src={src} alt={nombre} />
            </div>
            <div className="PaqueteCard-main">
                <h5>{nombre}</h5>
                <p className="font-small">{dias} dias / {noches} noches</p>
                <p className="font-small">Vuelo + Alojamiento + Tours</p>
                <p>{formatDinero(precio)} {moneda}</p>
            </div>
            <Button 
            variant="link" 
            className="PaqueteCard-verMas"
            onClick={onAction}
            >
                LEER MAS
            </Button>
        </div>
    )
}
export default PaqueteCard;