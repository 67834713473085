import "./index.css";
import Contain from "../../global/Contain";
import Title from "../../global/Title";
import Header from "../../global/Header";
import Footer from "../../global/Footer"
const AvisoPrivacidad = () => {
    return (
        <div>
            <Header/>
            <Contain>
            <Title titulo="Aviso de privacidad" />
            <div className="Aviso-main">
                <p>En cumplimiento de lo dispuesto por la Ley
                    Federal de Protección de Datos Personales en Posesión de los Particulares (en
                    lo sucesivo la “Ley”), el Reglamento de la Ley Federal de Protección de Datos
                    Personales en Posesión de los Particulares (en delante el “Reglamento”) y los
                    Lineamientos del Aviso de Privacidad publicado en el Diario Oficial de la
                    Federación el día 17 de enero de 2013 (en lo sucesivo los “Lineamientos”) y
                    demás disposiciones aplicables, Aceleradora de
                    Viajes, S.A. de C.V., (en lo
                    sucesivo “DodoViajes”) desea hacer del conocimiento del Usuario del los dominios o
                    sitios web www.dodoviajes.mx (en lo sucesivo el “Usuario”) así como de los
                    servicios prestados por otros medios, por DodoViajes, su Aviso de Privacidad
                    respecto del tratamiento y protección de los datos de carácter personal de
                    aquellas personas que voluntariamente se comunican, de forma enunciativa más no
                    limitativa, a través de correo electrónico, telefónicamente o cualquier otro
                    medio electrónico,&nbsp; oral o escrito con DodoViajes,
                    llenan formularios en que se recaben datos personales o que ingresen y/o al
                    sitio web aquí mencionado, si es que esto implica la comunicación de sus datos
                    personales. </p>



                <p>Para todos los efectos relacionados con el
                    presente Aviso de Privacidad, DodoViajes señala como domicilio el ubicado en Rio Nazas 171 &#8211; 1, Colonia
                    Cuauhtémoc Delegación Cuauhtémoc, Ciudad de México, CP: 06500, expidiendo y poniendo a
                    disposición del Usuario el presente Aviso de Privacidad, bajo los siguientes
                    términos:</p>



                <p><strong>I.&nbsp; Finalidad del tratamiento de sus datos
                    personales.-</strong> La
                    información personal que proporcione el Usuario será utilizada únicamente para:
                    i) actividades de&nbsp; administración interna;
                    ii) para formar el expediente del cliente; y iii) con fines de identificación
                    para el servicio que se presta. Estos datos son indispensables para el control
                    interno y para la administración del servicio que se presta así como para
                    formar una base de datos.&nbsp; </p>



                <p>Asimismo, sus datos personales podrán serán
                    utilizados para las siguientes actividades secundarias: i) para fines
                    mercadotécnicos; ii) fines publicitarios; y/o iii) prospección comercial, lo
                    anterior con el propósito de ofrecerle diversos servicios que prestan terceros.
                    Si usted no está de acuerdo con las finalidades secundarias, podrá manifestar
                    su negativa siguiendo el mecanismo previsto en el numeral “VI” del presente
                    Aviso. Es preciso señalar que en caso de actualizarse el supuesto antes
                    señalado, La Empresa no le podrá brindar los servicios que presta de forma
                    correcta y ésta no será responsable por tal situación.</p>



                <p><strong>II.&nbsp; Datos personales solicitados.-</strong> Para efectos del presente
                    Aviso de Privacidad se entenderá por dato de carácter personal, cualquier
                    información que se recabe concerniente a personas físicas o morales
                    identificadas o identificables; y por Usuario, a cualquier persona física o
                    moral identificada o identificable que comunique sus datos de carácter personal
                    a través del sitio web aquí mencionados que de manera enunciativa más no
                    limitativa pudiera ser a través de i) correo electrónico; ii) llenado de
                    formularios por los que se recaban datos personales en la página web; iii) de
                    manera personal al acudir a la empresa; y iv) en general el uso de cualquier
                    servicio presente del sitio web que impliquen la comunicación de sus datos
                    personales.</p>



                <p>Los datos personales que recabará DodoViajes del
                    Usuario son: </p>



                <p>i) nombre; ii) domicilio; iii) edad; iv) estado
                    civil; v) Número de Registro Federal de Contribuyentes; vi) Copia de credencial
                    para votar;&nbsp; vii) correo electrónico; y
                    viii) número de teléfono.&nbsp; </p>



                <p><strong>III. Sobre Redes Sociales</strong>.- DodoViajes &nbsp;hace del conocimiento del Usuario que está
                    presente en redes sociales, como Instagram, Facebook, Twitter y Snapchat. El
                    tratamiento de los datos del Usuario que se hagan seguidores de las páginas
                    oficiales de DodoViajes se regirá por las condiciones previstas en los términos y
                    condiciones de la red social que corresponda en virtud de que cualquier dato
                    que la red social requiera sea recabado es responsabilidad de ésta y no de DodoViajes
                    por lo que se recomienda el acceso a la política de privacidad y condiciones de
                    uso de las mencionadas páginas o en su caso, de la propia red social, con el
                    fin de conocer la información derivada del tratamiento de los datos de carácter
                    personal y especialmente las condiciones y finalidades a las que serán
                    destinados los datos que forman parte del perfil del Usuario.</p>



                <p><strong>IV. Resguardo de datos personales.-</strong> Los datos personales del
                    Usuario serán resguardados en base a los principios de licitud, consentimiento,
                    información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad,
                    mismos que se encuentran consagrados en la Ley. </p>



                <p><strong>V. Transferencia de datos.-</strong>&nbsp; DodoViajes no podrá transferir la información
                    personal recabada, salvo cuando sea solicitada por dependencias
                    gubernamentales, instituciones bancarias o de crédito, para la realización de
                    pagos y transferencias, y por empresas filiales; únicamente, cuando dicha
                    situación sea necesaria para la consecución de la finalidad de los servicios
                    prestados por DodoViajes. </p>



                <p><strong>VI. Cambios o modificaciones al Aviso.-</strong> DodoViajes se reserva el derecho
                    de modificar el presente Aviso por lo que hace de su conocimiento que cualquier
                    cambio o modificación al contenido del presente será publicado, de manera
                    generalizada en la página web www.dodoviajes.com Además se les estará dando aviso a
                    todos los usuarios por medio de correo electrónico. </p>



                <p><strong>VII.&nbsp; Medios para ejercer sus derechos.-</strong> De conformidad con lo
                    dispuesto en la Ley, el Usuario podrá acceder, rectificar y cancelar sus datos
                    personales así como oponerse a la divulgación de los mismos a través de los
                    procedimientos que DodoViajes ha implementado a través de su Área Administrativa.
                    El Usuario podrá solicitar el formato para acceder, rectificar, cancelar y
                    oponerse a la divulgación de los mismos. Asimismo tendrá un plazo de 5 días para
                    manifestar su negativa al tratamiento de sus datos personales para actividades
                    secundarias. Para más información sobre como ejercer sus Derechos de Acceso,
                    Rectificación, Cancelación y Oposición (Derechos ARCO), oponerse a la
                    divulgación de sus datos, revocar su consentimiento o manifestar negativa al
                    tratamiento de los mismos, deberá ponerse en contacto mediante el envío de un
                    correo electrónico a <a href="mailto:info@dooddo.com">info@dooddo.com</a> o bien, mediante una carta
                    dirigida a la empresa que se envíe por correo certificado. En los anteriores
                    casos se deberá especificar en el correo o en la carta los motivos por los
                    cuales El Usuario quiera que se cancele o restringa el uso de la información. </p>



                <p>De conformidad con el artículo octavo de la
                    Ley, por el simple hecho de no manifestar oposición al leer el presente Aviso
                    se entenderá su aceptación a los términos del mismo.</p>
            </div>
        </Contain>
        <Footer/>
        </div>
    )
}
export default AvisoPrivacidad;