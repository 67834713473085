import LogoDoodo from "./../../assets/white_logo.png";
import './index.css';
import { SITE_DOMAIN } from "./../../config/siteConfig"
const Header = () => {
    return (
        <div className="HeaderContainer-white">
            <div className="HeaderLogo-white">
                <a href={`${SITE_DOMAIN}`} >
                    <img src={LogoDoodo} alt="Dooddo" />
                </a>
            </div>
            <h5>Ahorra, viaja y repite</h5>
        </div>
    )
}
export default Header;