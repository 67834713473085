import "./Movil.css";
import Contain from "../../global/Contain";
import Title from "../../global/Title";
import { Accordion } from "react-bootstrap";
const PreguntasFrecuentesMovil = () => {
    return (
        <div>
            <Contain>
                <div className="preguntasFrecuentes-main">
                <Title titulo="Preguntas Frecuentes" />
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>¿Cómo poder solicitar información sobre cancelación o cambios en mi plan?</Accordion.Header>
                            <Accordion.Body>
                                Por favor para cualquier duda o comentario escribe un correo a <a href="mailto:te_escuchamos@dooddo.com ">te_escuchamos@dooddo.com</a> (Explicar específicamente cual es la situación a tratar).
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>¿Qué es DodoViajes?</Accordion.Header>
                            <Accordion.Body>
                                DodoViajes es la primera plataforma digital que permite a sus usuarios organizar viajes a través de pequeños pagos mensuales, teniendo excelentes beneficios como: mejores precios al vender por volumen, elimina comisiones bancarias, busca ofertas, atención personalizada, excelentes convenios a nivel mundial.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>¿Están registrados ante la secretaria de Turismo?</Accordion.Header>
                            <Accordion.Body>
                                Sí, nuestro número es: 04090151376 y la puedes solicitar a tu agente o descargar en nuestro footer.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>¿DodoViajes emite facturas?</Accordion.Header>
                            <Accordion.Body>
                                Sí, tanto a personas físicas como morales una vez que se adquieren sus servicios contratados. De igual forma puedes descargar tu estado de cuenta mensual en nuestra plataforma, donde podrás visualizar tus abonos y compras.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>¿Cómo inicio mi ahorro?</Accordion.Header>
                            <Accordion.Body>
                                Una vez que nos proporciones tus datos en el botón de “SOLICITAR INFORMACIÓN” un agente se comunicará contigo para conocer más a fondo tus requerimientos con respecto al viaje. Luego de tu llamada, tu agente te proporcionará una cotización y de ser aceptada, obtendrás un usuario y una contraseña para ingresar a nuestro sistema y comenzar a disfrutar de todos los beneficios de ahorrar con DodoViajes. En nuestra plataforma podrás realizar tus aportaciones mensuales, descargar estados de cuenta y checar el avance de tu ahorro.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>¿Mi dinero está seguro?</Accordion.Header>
                            <Accordion.Body>
                                Tu dinero se encuentra en una cuenta de BBVA siempre disponible y visible, con una referencia a tu nombre, se firma un contrato de prestación de servicios donde se especificarán los vuelos, tours, hospedaje y amenidades contratadas.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>¿Qué pasa si no puedo realizar mi viaje?</Accordion.Header>
                            <Accordion.Body>
                                ¡Descuida! Puedes cancelar tu plan de ahorro en el momento en que tú lo desees. Los gastos de cambio de nombre en aerolíneas corren por cuenta del cliente y DodoViajes solo cobrara un 10% por cargo de sus servicios realizados.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>¿Dónde están ubicados?</Accordion.Header>
                            <Accordion.Body>
                                Estamos ubicados en la Ciudad de México, aun así, atendemos a nivel nacional gracias a la tecnología que hoy nos permite acortar distancias y así poderte ofrecer mejores precios reduciendo nuestros gastos operativos.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>¿Existe algún beneficio si es un viaje de varias personas?</Accordion.Header>
                            <Accordion.Body>
                                Sí, a partir de 10 personas el líder del grupo u organizador podrá optar por excelentes beneficios. Si tienes un ahorro grupal como el mencionado, solicítale a tu agente que te platique los beneficios.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9">
                            <Accordion.Header>¿Por qué mi cotización es en dólares/euros/pesos?</Accordion.Header>
                            <Accordion.Body>
                                Esto se debe a que tu ahorro será cotizado tomando como referente al proveedor del viaje que estas adquiriendo, aun así, tu depósito en la plataforma será en pesos mexicanos.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="10">
                            <Accordion.Header>¿Cómo puedo depositar a mi cuenta de ahorro?</Accordion.Header>
                            <Accordion.Body>
                                Una vez que se formalice tu contrato y seas cliente DodoViajes, recibirás tu talón de aportaciones. Podrás depositar en ventanillas BBVA, practicajas BBVA, app BBVA, mediante nuestra plataforma con cualquier tarjeta de débito o crédito VISA, MASTERCARD o AMEX.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Contain>
        </div>
    )
}
export default PreguntasFrecuentesMovil;