import "./index.css"
import Sol from "./../../assets/detalle/sol.png"
import Luna from "./../../assets/detalle/luna.png"
import Aero from "./../../assets/detalle/aero.png"
import { formatDinero } from "./../../utils/getDinero"
import { FormatoFecha } from './../../utils/getFecha'
import { useParams } from "react-router"
import { usePaqueteProveedor } from "../../hooks/usePaqueteProveedor"
import { APIURL } from "../../config/siteConfig"
const CardDetalle = (props) => {

    const {clavePaquete} = useParams()
    const {  dias = "", noches = "", precio = 0, moneda = "", fechaSalida = "2021-12-12" } = props
    const [paqueteProveedor] = usePaqueteProveedor({ClavePaquete: clavePaquete})
    console.log("🚀 ~ file: index.jsx ~ line 15 ~ CardDetalle ~ paqueteProveedor", paqueteProveedor)
    
    return (
        <div className="CardPaqueteDetalle">
            <div className="CardPaqueteDetalle-Dias">
                <div className="flex-column">
                    <div className="flex-row">
                        <img className="icon" src={Sol} alt="Sol" />
                        <h2>{dias}</h2>
                    </div>
                    <h6>Dias</h6>
                </div>
                <h2>+</h2>
                <div className="flex-column">
                    <div className="flex-row">
                        <img className="icon" src={Luna} alt="Luna" />
                        <h2>{noches}</h2>
                    </div>
                    <h6>Noches</h6>
                </div>
            </div>
            <div className="CardPaqueteDetalle-vuelos">
                <h4>Volando con:</h4>
                <div>
                    {
                        (paqueteProveedor.length === 0) &&
                        <img src={Aero} alt="AeroMexico" />
                    }
                    {
                        (paqueteProveedor.length > 0) &&
                        <img src={`${APIURL}/Supplier/${paqueteProveedor[0]?.proveedor?.IdProveedor}/Logo`} alt={paqueteProveedor[0]?.proveedor?.RazonSocial} />
                    }
                </div>
            </div>
            <div className="CardPaqueteDetalle-Salidas">
                <h4>Saliendo en:</h4>
                <h2 className="BlueColor">
                    {
                        (fechaSalida) &&
                        FormatoFecha({ date: fechaSalida.split("T")[0], formato: 'MMMM yyyy' })
                    }
                </h2>
            </div>
            <div className="CardPaqueteDetalle-Montos">
                <h6>Mensualidades desde</h6>
                <h2 className="Green">{formatDinero(precio)} {moneda}</h2>
                <h6>*Revisa los suplementos</h6>
            </div>
        </div>
    )
}
export default CardDetalle;