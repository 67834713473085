import {useEffect, useState} from "react"
import { getPaqueteById } from "../services/paqueteService"
export const usePaquete = ({idPaquete}) =>{
    const [paquete, setPaquete] = useState({});
    useEffect(() =>{
        Promise.all([getPaqueteById({idPaquete})]).then(Response => {
            setPaquete(Response[0]);
        }).catch(Error => {
            setPaquete({});
        })
        
        return () =>{
            setPaquete({});
        }
    },[idPaquete])
    return [paquete];
}