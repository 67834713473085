import {useEffect, useState} from "react";
import { getDetallePaquete } from "../services/paqueteDetalleService";
export const useDetallePaquete = ({idPaquete}) =>{
    const [detalle, setDetalle] = useState({});
    useEffect(() =>{
        Promise.all([getDetallePaquete({idPaquete})]).then(Response => {
            setDetalle(Response[0]);
        }).catch(Error => {
            setDetalle({});
        })
        return () =>{
            setDetalle({});
        }
    },[idPaquete])
    return [detalle];
}