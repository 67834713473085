import "./index.css";
import Contain from "../../global/Contain";
import Button from "./../../global/Boton"
import Header from "./../../global/HeaderTransparent";
import Footer from "../../global/Footer"
import clip from './../../assets/bg-video/video.webm';
import Poster from './../../assets/bg-video/poster.jpg';
import { SITE_DOMAIN,SITE_CLIENT_URL  } from "../../config/siteConfig"
const VideoBack = () => {
    return (
        <div className="bg-video">
            <video className="HomeVideoBack" autoPlay loop muted poster={Poster}>
                <source src={clip} type='video/webm' />
            </video>
        </div>
    )
}
const Home = () => {
    return (
        <div>
            <VideoBack />
            <Header />
            <Contain>
                <div className="HomeContainer">
                    <div className="HomeItem center">
                        <h1>EXPLORA EL MUNDO</h1>
                        <h2>JUNTO A DODOVIAJES</h2>
                    </div>
                    <div className="HomeItem left">
                        <Button link={true} target="_blank" href={SITE_CLIENT_URL} >INICIA SESIÓN</Button>
                    </div>
                    <div className="HomeItem rigth">
                        <Button link={true} href={`${SITE_DOMAIN}destinos`}>VER PAQUETES</Button>
                    </div>
                </div>
            </Contain>
            <Footer/>
        </div>
    )
}
export default Home;