import "./index.css"
import { Tab, Tabs } from "react-bootstrap";
import { useSalidas } from "../../hooks/useSalidas";
import Salidas from "./Salidas"
import TablaSalidas from "./TablaSalidas"
import Suplemento from "./Suplemento"
import Extra from "./Extras"
import Pais from "./Pais"
import { FormatoFecha } from './../../utils/getFecha'
import { useSuplemento } from "./../../hooks/useSuplmentos"
import { useExtras } from "./../../hooks/useExtras"
import { useCiudades } from "../../hooks/useCiudades";
import { useDetallePaquete } from "../../hooks/usePaqueteDetalle";

const TabDetalle = (props) => {
    const { id = 0, precioPublico = 0, moneda = "" } = props;
    const [salidas] = useSalidas({ idPaquete: id })
    const [suplementos] = useSuplemento({ idPaquete: id });
    const [extras] = useExtras({ idPaquete: id });
    const [ciudades] = useCiudades({ idPaquete: id });
    const [detalle] = useDetallePaquete({ idPaquete: id });

    const combineArray = () => {
        let salidasArray = [].concat.apply([], salidas.map((Bloque) => {
            return Bloque.Salidas.map(salida => {return {...salida, postumo: Bloque.Descripcion.includes("POSTUMO")}})
        }))
        let ArrayGeneral = []
        salidasArray.forEach(value => {
            const anho = FormatoFecha({ date: value.FechaSalida.split("T")[0], formato: 'yyyy' })
            const dia = FormatoFecha({ date: value.FechaSalida.split("T")[0], formato: 'dd' })
            const mes = FormatoFecha({ date: value.FechaSalida.split("T")[0], formato: 'MMMM' })
            const mesNumero = FormatoFecha({ date: value.FechaSalida.split("T")[0], formato: 'MM' })
            let objectFecha = {
                postumo: value.postumo,
                anho: {
                    descripcion: anho,
                    mes: {
                        descripcion: mes,
                        mesNumero: mesNumero,
                        dia: dia
                    },
                },
            }
            ArrayGeneral.push(objectFecha)
        })
        return ArrayGeneral.sort((a, b) => {
            return Number(a.anho.mes.dia) - Number(b.anho.mes.dia)
        }).sort((a, b) => {
            return Number(a.anho.mes.mesNumero) - Number(b.anho.mes.mesNumero)
        }).sort((a, b) => {
            return a.anho.descripcion - b.anho.descripcion
        })
    }

    const FechasComponent = (Salida, index) => {
        return (<div key={index} style={{ display: "flex", justifyContent: "center" }}>
            <Salidas
                Postumo={Salida.postumo}
                Anho={Salida.anho.descripcion}
                Mes={Salida.anho.mes.descripcion}
                Dia={Salida.anho.mes.dia}
            />
        </div>)
    }

    return (
        <Tabs defaultActiveKey="itinerario" id="uncontrolled-tab-example" className="TabDetalleContent">
            <Tab eventKey="itinerario" title="ITINERARIO" unmountOnExit={true}>
                {
                    (detalle.Itinerario) &&
                    <div className="TabDetalleItemContent" dangerouslySetInnerHTML={{ __html: `${detalle.Itinerario}` }} />

                }
            </Tab>
            <Tab eventKey="incluye" title="¿QUE INCLUYE?" unmountOnExit={true}>
                <div className="TabDetalleItemContent">
                    <h2 className="TabSubTitulo green">EL VIAJE INCLUYE:</h2>
                    <div dangerouslySetInnerHTML={{ __html: `${detalle.Incluye}` }} />
                    <h2 className="TabSubTitulo blue">EL VIAJE NO INCLUYE:</h2>
                    <div dangerouslySetInnerHTML={{ __html: `${detalle.NoIncluye}` }} />
                </div>

            </Tab>
            <Tab eventKey="ciudades" title="CIUDADES" unmountOnExit={true}>
                <h2 className="TabDetalleTitle">CIUDADES</h2>
                <Pais
                    Paises={ciudades}
                />
            </Tab>
            <Tab eventKey="suplmento" title="SUPLEMENTO" unmountOnExit={true}>
                <h2 className="TabDetalleTitle">TARIFAS</h2>
                <Suplemento
                    Suplementos={suplementos}
                    precioPublico={precioPublico}
                    moneda={moneda}
                />
                <h2 className="TabDetalleTitle">SUPLEMENTOS</h2>
                <TablaSalidas
                    BloqueSalidas={salidas}
                    precioPublico={precioPublico}
                    moneda={moneda}
                />
            </Tab>
            <Tab eventKey="fechas" title="MAS FECHAS" unmountOnExit={true}>
                <h2 className="TabDetalleTitle">MAS SALIDAS</h2>
                {
                    combineArray().map((salida, index) => FechasComponent(salida, index))
                }
            </Tab>
            <Tab eventKey="tours" title="OPCIONALES" unmountOnExit={true}>
                <h2 className="TabDetalleTitle">OPCIONALES</h2>
                {
                    extras.map((extra) =>
                        <Extra
                            key={extra.Extra.IdExtra}
                            Nombre={extra.Extra.Nombre}
                            Suplemento={extra.Suplemento}
                            Moneda={moneda}
                            Descripcion={extra.Extra.Descripcion}
                        />
                    )
                }
            </Tab>
        </Tabs>
    )
}
export default TabDetalle;