import {useEffect, useState} from "react"
import { getPaqueteGaleria } from "../services/paqueteService"
export const useGaleria = ({clavePaquete, idTipoImagen}) =>{
    const [paqueteGaleria, setPaqueteGaleria] = useState([]);
    useEffect(() =>{
        Promise.all([getPaqueteGaleria({clavePaquete, idTipoImagen})]).then(Response => {
            setPaqueteGaleria(Response[0]);
        }).catch(Error => {
            setPaqueteGaleria([]);
        })
        
        return () =>{
            setPaqueteGaleria([]);
        }
    },[clavePaquete, idTipoImagen])
    return [paqueteGaleria];
}